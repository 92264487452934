@import url(https://fonts.googleapis.com/css2?family=Work+Sans&display=swap);
body {
  margin: 0;
  font-family:"Work Sans",Roboto,Helvetica,Arial,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.logo img {
  width: 100%;
}

.logo {
  margin-bottom: 20px; 
  text-align: center;
}

.tagline {
  margin: 0 20px 60px;
}

.footer {
  margin: 60px;
  line-height: 36px;
}

h1 {
  margin-top: 2em;
  font-size: 4em;
}

